/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import HomePage from "views/Home";
import Thankyou from "views/Thankyou";
import ThemeSelector from "ThemeSelector";
import DataProvider from "DataContainer";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <ThemeSelector>
        <Switch>
          <Route path="/:id" exact render={(props) => <HomePage {...props} />} />
          <Route path="/:id/thank-you" exact render={(props) => <Thankyou {...props} />} />
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/en" />;
            }}
          />
        </Switch>
      </ThemeSelector>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
