import React, { useEffect } from "react";

import LandingPageHeader from "components/Headers/LandingPageHeader.js";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";
import TextGrid from "components/TextGrid";
import Conferences from "components/Conferences";
import ManagedEvents from "components/ManagedEvents";
import Trainings from "components/Trainings";
import Support from "components/Support";
import ContactUs from "components/ContactUs";
import Upcoming from "components/Upcoming";

function RegisterPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section id="home" />
      <NavbarDiv />
      <LandingPageHeader />
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/v748-toon-131.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <TextGrid />
        <section id="conferences" />
        <Conferences />
        <section id="managedEvents" />
        <ManagedEvents />
        <section id="trainings" />
        <Trainings />
        <Upcoming />
        <Support />
        <section id="contact" />
        <ContactUs />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
