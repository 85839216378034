import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import text from "views/text";
function Sensors() {
  let pageHeader = React.createRef();

  const { lang } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-main text-white mb-5">{text[lang].upcoming.title}:</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={12}>
              <a href="https://ksa.digitaltwin-summit.com/">
                <img src={require(`assets/images/logo/dtsa.png`)} width="100%" alt="logo" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sensors;
