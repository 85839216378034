import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import text from "views/text";

function Conferences() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const { lang } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <div className="mt-5" style={{ position: "sticky", top: size ? "8rem" : "3rem" }}>
                <h1 className="text-main text-white mb-5">{text[lang].trainings.title}</h1>
                <h3 className="text-white text-300 mt-5">
                  {text[lang].trainings.text.map((t) => (
                    <>
                      {t}
                      <br />
                      <br />
                    </>
                  ))}
                </h3>
              </div>
            </Col>
            <Col lg={6}>
              <div className="py-3">
                <img
                  src={require("assets/images/content/5.jpg")}
                  width="100%"
                  alt="perception"
                  style={{
                    border: "1px solid #fff",
                  }}
                />
              </div>
              <div className="py-3">
                <img
                  src={require("assets/images/content/6.jpg")}
                  width="100%"
                  alt="perception"
                  style={{
                    border: "1px solid #fff",
                  }}
                />
              </div>
            </Col>
            <Col lg={6} className="align-self-center"></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Conferences;
