import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import text from "views/text";

function TextGrid() {
  let pageHeader = React.createRef();
  const { lang } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-main text-white">{text[lang].aboutUS}</h1>

              <h3 className="text-white text-300 mt-5">
                {text[lang].aboutText.map((a) => (
                  <>
                    {a}
                    <br />
                    <br />
                  </>
                ))}
              </h3>
            </Col>
          </Row>
          <Row>
            {text[lang].aboutInclusion.map((c, index) => (
              <Col lg={4} xs={12} key={index} className="py-2">
                <Card style={{ background: "transparent" }}>
                  <Container fluid>
                    <Row>
                      <Col
                        lg={3}
                        xs={3}
                        className=" py-3"
                        style={{ borderRight: "3px solid #595959" }}
                      >
                        <img
                          src={require(`assets/images/icons/${c.image}`)}
                          alt=""
                          width="100%"
                          style={{ maxWidth: "100px" }}
                        />
                      </Col>
                      <Col lg={9} xs={9} className="align-self-center text-white">
                        <h3 className="text-400 m-0">{c.title}</h3>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
